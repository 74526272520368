import React from "react";

function About() {
  return (
    <div className="p-8">
      <h1 className="text-xl font-bold">About WAsurge</h1>
      <br />
      <p>
        WAsurge is a powerful Chrome extension designed to enhance your WhatsApp
        experience and help you save time by automating various tasks. Our goal
        is to provide a user-friendly tool that simplifies communication and
        makes managing your WhatsApp conversations more efficient and enjoyable.
      </p>
      <br />
      <p>
        Our team of dedicated developers has worked tirelessly to create an
        extension that offers a range of features tailored to the needs of
        individuals and businesses alike. WAsurge is a one-stop solution for
        WhatsApp users who want to take their experience to the next level.
      </p>
      <br />
      <p>
        We understand the importance of privacy and security, which is why we
        have implemented industry-standard measures to protect your personal
        information. WAsurge operates independently and is not affiliated with
        WhatsApp or Facebook, ensuring that your data remains secure and under
        your control.
      </p>
      <br />
      <p>
        We are committed to continuous improvement and welcome feedback from our
        users. If you have any questions, suggestions, or concerns, please feel
        free to contact our support team at support@wasurge.com. We're here to
        help and ensure you have the best possible experience with WAsurge.
      </p>
      <br />
      <p>
        Thank you for choosing WAsurge as your go-to WhatsApp Chrome extension.
        We hope it brings you the convenience and efficiency you deserve in your
        daily communications.
      </p>
      <br />
    </div>
  );
}

export default About;
