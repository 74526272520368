import React, { useEffect, useRef, useState } from "react";
import BarPill from "../components/BarPill";
import BarPill2 from "../components/BarPill2";
import BgContainer from "../components/BgContainer";
import Plans from "../components/Plans";
import SvgVectors from "../components/SvgVectors";
import Faq from "../components/core/Faq";
import Faq2 from "../components/core/Faq2";
import IosSwitch from "../components/core/IosSwitch";
import { useMixpanel } from "../services/mixpanel/mixpanelContext";
import { MixpanelEvents } from "../services/mixpanel/mixpanelEvent";
import Arrow from "../svgs/Arrow";
import Card from "../svgs/Card";
import CheckIcon from "../svgs/CheckIcon";
import CheckList from "../svgs/CheckList";
import MyClose from "../svgs/Close";
import CloudIcon from "../svgs/CloudIcon";
import CodeIcon from "../svgs/CodeIcon";
import Graph from "../svgs/Graph";
import HamBurger from "../svgs/HamBurger";
import InstagramIcon from "../svgs/InstagramIcon";
import LayoutIcon from "../svgs/LayoutIcon";
import Logo from "../svgs/Logo";
import MobileIcon from "../svgs/MobileIcon";
import SmartFeature from "../svgs/SmartFeature";
import StarFilledIcon from "../svgs/StarFilledIcon";
import StoreIcon from "../svgs/StoreIcon";
import Tweeter from "../svgs/Tweeter";
import Youtube from "../svgs/Youtube";
import ChromeLogo from "../svgs/chromeLogo";

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function Homepage() {
  const [expanded, setExpanded] = useState("panel");
  const [theme, setTheme] = useState("light");
  const [hamBurger, setHamBurger] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { mixpanel, isMixpanelInitialized } = useMixpanel();
  const triggerRef = useRef(null);

  const handleChangeFaq = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const trigger = ScrollTrigger.create({
      trigger: triggerRef.current,
      start: "top bottom",
      onEnter: () => {
        console.log("triggered");
        setLoaded(true);
      },
      // markers: true,
    });
    return () => trigger.kill();
  }, []);

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);


  useEffect(() => {
    if (isMixpanelInitialized) {
      mixpanel.track_pageview({ page: "Homepage" });
    }
  }, [isMixpanelInitialized, mixpanel]);

  useEffect(() => {
    // This effect runs once on component mount.
    const hash = window.location.hash;
    if (hash === '#pricing') {
      const pricingElement = document.getElementById('pricing');
      if (pricingElement) {
        pricingElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }

    if (hash === '#testimonial') {
      const testimonialElement = document.getElementById('testimonial');
      if (testimonialElement) {
        testimonialElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  const handleThemeSwitch = () => {
    mixpanel.track(MixpanelEvents.TOGGLE_DARK_MODE);
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const [plan, setPlan] = useState(true);

  const data = {
    monthly: {
      free: [
        "Bulk Messaging",
        "Personalized Messages",
        "Group Message Sending",
        "30 Messages / Day",
        "99.9% Uptime",
        "Email Support",
      ],
      paid: [
        "Includes Free Plan Features",
        "50000 Messages / Month",
        "Enable Link Preview",
        "Subscription Transfer (2 times)",
        "Early Access to Updates",
        "Email Support (48 hrs)",
      ],
      enterprise: [
        "Includes Paid Plan Features",
        "Unlimited Messages",
        "Priority Email Support (24 hrs)",
        "WhatsApp Support",
      ],
    },
    yearly: {
      free: [
        "Bulk Messaging",
        "Personalized Messages",
        "Group Message Sending",
        "30 Messages / Day",
        "99.9% Uptime",
        "Email Support",
      ],
      paid: [
        "Includes Free Plan Features",
        "50000 Messages / Month",
        "Enable Link Preview",
        "Subscription Transfer (2 times)",
        "Early Access to Updates",
        "Email Support (48 hrs)",
      ],
      enterprise: [
        "Includes Paid Plan Features",
        "Unlimited Messages",
        "Priority Email Support (24 hrs)",
        "WhatsApp Support",
      ],
    },
  };

  const FaqData1 = [
    {
      summary: "Can I send unlimited messages with the free plan?",
      description:
        "You can send up to 30 messages per day with the free plan. If you want to send unlimited messages, you can upgrade to the paid plan.",
    },
    {
      summary: "What additional features does the paid plan offer?",
      description:
        "The paid plan provides a number of advanced features. These include enabling link previews, uploading contacts via CSV/Excel, sending attachments and images with captions, and personalizing sender names. Plus, you can send 50000 messages per month. Additionally, you have the option to transfer your subscription up to two times.",
    },
    {
      summary: "Is my data secure?",
      description:
        "Absolutely. The plugin runs on your system and we do not collect or share any of your data.",
    },
    {
      summary: "Will my WhatsApp number get blocked if I use this extension?",
      description:
        "While we provide guidelines to minimize the risk, the final decision is up to WhatsApp. Adhering to our guidelines, there's a 99.9% chance of avoiding a block.",
    },
  ];

  const FaqData2 = [
    {
      summary:
        "What is the recommended number of messages I can send in a day?",
      description:
        "While technically unlimited, excessive messaging may lead to a block by WhatsApp. We recommend starting with 100-200 messages per day and gradually increasing to 1000-2000 messages per day.",
    },
    {
      summary: "How do I get support if I face any issues?",
      description: "For any issues, please email us at support@wasurge.com.",
    },
    {
      summary: "Can I get a one-month trial of the paid plan?",
      description:
        "We currently do not offer a one-month trial for the paid plan.",
    },
    {
      summary: "How will I receive updates for the extension?",
      description:
        "The extension automatically updates whenever we release a new version through Google Chrome.",
    },
    {
      summary: "Can I use this extension on multiple devices?",
      description:
        "Yes, you can use the extension on any device with the Chrome browser. However, your subscription is tied to your phone number and can only be transferred to another number up to two times with the paid plan.",
    },
  ];

  return (
    <>
      <div className=" dark:bg-primaryBg-dark">
        <div
          className={`${hamBurger
            ? "fixed z-10 h-full w-screen bg-white  dark:bg-primaryBg-dark "
            : "hidden"
            }`}
        >
          <div className="flex flex-col items-end pr-10 text-xl font-medium gap-y-6 dark:text-white">
            <div
              className="inline mt-14 "
              onClick={(e) => {
                setHamBurger(false);
              }}
            >
              <MyClose fillColor={theme === "light" ? "black" : "white"} />
            </div>
            <button
              className="inline"
              onClick={() => {
                mixpanel.track(MixpanelEvents.NAV_DOWNLOAD_BUTTON, { source: 'mobile' });
                window.open(
                  "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                );
              }}
            >
              Download
            </button>
            <button className="inline" onClick={() => {
              mixpanel.track(MixpanelEvents.NAV_TESTIMONIALS_BUTTON, { source: 'mobile' });
            }}>
              <a href="#testimonial">Testimonials</a>
            </button>
            <button className="inline" onClick={() => {
              mixpanel.track(MixpanelEvents.NAV_PRICING_BUTTON, { source: 'mobile' });
            }}>
              <a href="#pricing">Pricing</a>
            </button>
          </div>
        </div>

        <div className="bg-[#f6f6f9] py-2 text-center    text-sm font-semibold dark:bg-[#1d243c] dark:text-white  md:py-4 ">
          Save 15% on memberships for a limited time only → Use Code OFFER15
        </div>
        {/* Stick Bar */}
        <div className="px-5 py-8 mb-5  flex items-center  justify-between md:mx-auto sticky top-0 z-50 bg-white dark:bg-[#1A202C] shadow-lg md:px-32 md:h-[80px] perspective-[2000px]">
          {/* left logo side */}
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              window.open("https://wasurge.com/");
            }}
          >
            <span className="mr-2">
              <Logo />
            </span>
            <div className="font-bold dark:text-white">WAsurge</div>
          </div>
          {/*  */}
          <div className="hidden md:block">
            <div className="flex font-semibold gap-x-5 dark:text-white">
              <button
                onClick={() => {
                  mixpanel.track(MixpanelEvents.NAV_DOWNLOAD_BUTTON, { source: 'desktop' });
                  window.open(
                    "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                  );
                }}
              >
                Download
              </button>
              <button onClick={() => {
                mixpanel.track(MixpanelEvents.NAV_TESTIMONIALS_BUTTON, { source: 'desktop' });
              }}>
                <a href="#testimonial">Testimonials</a>
              </button>
              <button onClick={() => {
                mixpanel.track(MixpanelEvents.NAV_PRICING_BUTTON, { source: 'desktop' });
              }}>
                <a href="#pricing">Pricing</a>
              </button>
            </div>
          </div>

          {/* right theme change side */}
          <div className="flex items-center dark:text-white">
            {/* <button onClick={handleThemeSwitch}>dark theme</button> */}
            <IosSwitch
              handleThemeSwitch={handleThemeSwitch}
              theme={theme}
              setTheme={setTheme}
            />
            <button
              className="items-center justify-center rounded border-4 border-solid  border-black bg-black px-5 py-1.5 font-bold text-white hover:opacity-70 dark:border-white dark:bg-white dark:font-bold dark:text-black dark:hover:opacity-70 ml-5 hidden md:flex"
              onClick={() => {
                mixpanel.track(MixpanelEvents.ADD_TO_CHROME_BUTTON, { source: 'nav' });
                window.open(
                  "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                );
              }}
            >
              <ChromeLogo fillColor={theme === "light" ? "white" : "black"} />
              <span className="ml-1 mr-1"> Add to Chrome</span>
            </button>
            <div
              className="inline-block md:hidden "
              onClick={(e) => {
                setHamBurger(true);
              }}
            >
              <HamBurger
                fillColor={theme === "light" ? "black" : "white"}
              />
            </div>
          </div>
        </div>
        <section className="w-full ">
          {/* container */}
          <BgContainer>

            <div className="flex items-center justify-center">
              <BarPill
                textOne={"NEW"}
                textTwo={"Latest features"}
                arrow={true}
              />
            </div>
            <div className="text-center text-5xl font-bold  dark:text-white md:text-[81px] md:leading-[89.1px]">
              <h1 className="md:mx-auto md:w-[80%] md:leading-[90px] md:tracking-[1px]">
                #1 WhatsApp Automation Tool For{" "}
                <span className="underline-text">Businesses</span>
              </h1>
            </div>

            <div className="my-8  mx-5 text-center text-xl dark:text-white md:mx-auto md:w-[40%] md:text-xl">
              Power up your WhatsApp outreach!
            </div>
            <div className="relative mt-[40px] flex justify-center gap-5">
              <button
                className="flex items-center justify-center rounded border-4 border-solid  border-black bg-black px-5 py-1.5 font-bold text-white hover:opacity-70 dark:border-white dark:bg-white dark:font-bold dark:text-black dark:hover:opacity-70"
                onClick={() => {
                  mixpanel.track(MixpanelEvents.ADD_TO_CHROME_BUTTON, { source: 'main' });
                  window.open(
                    "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                  );
                }}
              >
                <ChromeLogo fillColor={theme === "light" ? "white" : "black"} />
                <span className="ml-1 mr-1"> Add to Chrome</span>
                <span className="text-xs"> (it's free)</span>
              </button>
              <div className="absolute top-[-150%] right-[30%] hidden rotate-[-60deg] transform md:block">
                <Arrow
                  fillColor={theme === "light" ? "black" : "white"}
                />
              </div>
            </div>
          </BgContainer>
        </section>

        <section className="w-full dark:bg-primaryBg-dark md:pb-[90px]">
          <div className="text-center text-4xl font-bold  dark:text-white md:text-[60px] md:leading-[66px]">
            <h1 className="md:mx-auto md:w-[70%] md:leading-[65px] md:tracking-[1px]" ref={triggerRef}>
              Effortlessly Engage with Over 5000 Clients in One Click
            </h1>
          </div>
          <div className="my-8 mx-2 mb-[20px] text-center text-xl dark:text-white md:mx-auto md:w-[45%] md:text-xl">
            Leverage our innovative features to connect with your leads,
            customers, and potential clients seamlessly and cost-effectively,
            enhancing your reach and impact
          </div>
          {
            loaded && <div className="flex flex-col-reverse px-2 py-4 gap-x-10 dark:text-white md:flex-row md:items-center md:px-40 ">
              {/* left side speed */}
              <div className="px-6 pt-[60px] pb-[30px] md:pb-0">
                <div className="flex ">
                  <BarPill2
                    textOne={"SPEED"}
                    textTwo={"Increase Productivity"}
                    arrow={true}
                    w={"fit-content"}
                  />
                </div>
                <div className=" mt-5 flex text-4xl font-bold  dark:text-white md:text-[60px] md:leading-[66px]">
                  <h1 className=" text-[36px] md:text-5xl md:leading-[52.48px] md:tracking-[1px]">
                    Focus on delivering real value to your customers
                  </h1>
                </div>
                <p className="mt-9 text-[20px]">
                  Take full control of your data and streamline your messaging
                  process with our Bulk WhatsApp Sender. Just a simple download
                  and you're ready to leverage our impactful features. Discover
                  the simplicity that drives substantial results.
                </p>
                <div className="flex flex-col mt-5 md:flex-row">
                  <div className="space-y-2 md:w-[30%]">
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Easy to use
                    </div>
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Bulk Sender
                    </div>
                  </div>
                  <div className="space-y-2 ">
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Group Automations
                    </div>
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Secured, No Data Sharing
                    </div>
                  </div>
                </div>
              </div>

              {/* right two mini */}
              <div className="relative flex mx-auto md:items-center md:justify-center ">
                <div className="font flex w-[350px] items-center justify-center rounded-3xl p-10 text-2xl text-[#f6f6f9] shadow-boxShadow2 dark:text-[#1d243c] dark:shadow-boxShadowVideo md:m-[30px] md:h-[416px] md:w-[516px] md:text-5xl">
                  Bulk WhatsApp Sender Chrome Extension. WhatsApp Mass Messaging
                  Extension. WhatsApp Marketing Automation Tool.
                </div>
                <div className="  absolute top-[-10px] right-0 rounded-[30px] bg-transparent shadow-boxShadow2 dark:shadow-boxShadowVideo  md:top-0 md:right-0  ">
                  <div
                    className=" before:content-[` `] relative flex rounded-[30px] p-5 backdrop-blur-[6px] before:absolute 
               before:inset-0 before:rounded-[30px] before:opacity-[-1] dark:before:bg-[#070b1b] md:p-7 "
                  >
                    <div className="mr-3">
                      <SvgVectors
                        bgcolor={theme === "light" ? "#d5d2fa" : "#d5d2fa"}
                        svg={
                          <SmartFeature
                            fillColor={theme === "light" ? "#4946fe" : "#4946fe"}
                          />
                        }
                      />
                    </div>

                    {/* <div className="flex justify-center items-center rounded-full dark:bg-blue-600  h-[50px] w-[50px] p-2 dark:bg-opacity-20 ">
                  <SmartFeature fillColor={theme === 'light' ? 'blue' : 'blue'} />
                </div> */}
                    <div>
                      <div className="text-[20px] font-bold">Smart features</div>
                      <div className="text-[14px] font-bold text-slate-400">
                        All the tools you need
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" absolute left-0 bottom-[-10px]  rounded-[30px] bg-transparent  shadow-boxShadow2 dark:shadow-boxShadowVideo md:left-0  md:bottom-0">
                  <div className="before:content-[` `] relative flex rounded-[30px] p-5 backdrop-blur-[6px] before:absolute before:inset-0 before:rounded-[30px] before:opacity-[-1] dark:before:bg-[#070b1b]  md:p-7 ">
                    <div>
                      <div className="text-[20px] font-bold">Easy to use</div>
                      <div className="text-[14px] font-bold text-slate-400 ">
                        Made for everyone
                      </div>
                    </div>
                    <div className="ml-3">
                      <SvgVectors
                        bgcolor={theme === "light" ? "#c9f8e1" : "#c9f8e1"}
                        svg={
                          <CheckIcon
                            fillColor={theme === "light" ? "#05b86b" : "#05b86b"}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </section>


        {loaded && <div>
          {/* number  */}
          <section className="py-10 dark:bg-primaryBg-dark ">
            <div className="mb-[40px]  flex flex-col   gap-x-10 dark:text-white  md:flex-row md:items-center md:py-[60px] md:px-40">
              {/* left side speed */}
              <div className="pb-[90px] md:pb-0">
                <div className="flex flex-row justify-center gap-x-4">
                  {/* 1 */}
                  <div className="relative flex flex-col space-y-3 top-10">
                    <div className="flex w-[160px]  flex-col space-y-5 rounded-[30px]   p-[15px] shadow-boxShadow2 transition-all duration-200 ease-in-out md:h-[250px] md:w-[253px] md:p-[30px]">
                      <SvgVectors
                        bgcolor={theme === "light" ? "#f300de24" : "#f300de24"}
                        svg={
                          <CloudIcon
                            fillColor={theme === "light" ? "#ed26d0" : "#ed26d0"}
                          />
                        }
                      />
                      <div className="text-4xl font-bold ">1M+</div>
                      <div className="text-xl font-medium">Messages Sent</div>
                      <div className="text-base">till now</div>
                    </div>
                    <div className="flex w-[160px]  flex-col space-y-5 rounded-[30px]  p-[15px] shadow-boxShadow2 transition-all duration-200 ease-in-out md:h-[250px] md:w-[253px] md:p-[30px]">
                      <SvgVectors
                        bgcolor={theme === "light" ? "#fabd2f3d" : "#fabd2f3d"}
                        svg={
                          <StarFilledIcon
                            fillColor={theme === "light" ? "#ffc20d" : "#ffc20d"}
                          />
                        }
                      />
                      <div className="text-4xl font-bold ">10,000+</div>
                      <div className="text-xl font-medium">Happy & Satisfied</div>
                      <div className="text-base">Customers</div>
                    </div>
                  </div>
                  {/* 2 */}
                  <div className="relative flex flex-col space-y-3 bottom-10">
                    <div className="flex w-[160px]  flex-col space-y-5 rounded-[30px]  p-[15px] shadow-boxShadow2 transition-all duration-200 ease-in-out md:h-[250px] md:w-[253px] md:p-[30px]">
                      <SvgVectors
                        bgcolor={theme === "light" ? "#00cae429" : "#00cae429"}
                        svg={
                          <LayoutIcon
                            fillColor={theme === "light" ? "#00d0f9" : "#00d0f9"}
                          />
                        }
                      />
                      <div className="text-4xl font-bold ">5 star</div>
                      <div className="text-xl font-medium">User reviews</div>
                      <div className="text-base">Our clients love us</div>
                    </div>
                    <div className="flex w-[160px] flex-col space-y-5 rounded-[30px]  p-[15px] shadow-boxShadow2 transition-all duration-200 ease-in-out md:h-[250px] md:w-[253px] md:p-[30px]">
                      <SvgVectors
                        bgcolor={theme === "light" ? "#5637f038" : "#5637f038"}
                        svg={
                          <CodeIcon
                            fillColor={theme === "light" ? "#6338ef" : "#6338ef"}
                          />
                        }
                      />
                      <div className="text-4xl font-bold ">50,000+</div>
                      <div className="text-xl font-medium">WhatsApp Groups</div>
                      <div className="text-base">Automated</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right two mini */}
              <div className="px-6">
                <div className="flex ">
                  <BarPill2
                    textOne={"SPEED"}
                    textTwo={"Increase Productivity"}
                    arrow={true}
                    w={"fit-content"}
                  />
                </div>
                <div className=" mt-5 flex text-4xl font-bold  dark:text-white md:text-[55px] md:leading-[66px] ">
                  <h1 className=" md:leading-[65px] md:tracking-[1px]">
                    Engineered to Grow with Your Business: Optimize with Bulk
                    WhatsApp Sender
                  </h1>
                </div>
                <p className="mt-9 text-[20px]">
                  WAsurge employs cutting-edge technology for a fast, seamless
                  experience with our Bulk WhatsApp Sender. Scale effortlessly as
                  your business expands.
                </p>

                <div className="flex flex-col mt-5 md:flex-row">
                  <div className="space-y-2 md:w-[40%]">
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Designed to scale
                    </div>
                    <div className=" flex  items-center gap-x-4 text-[16px] md:text-[18px] ">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Upload Spreadsheets
                    </div>
                    <div />
                  </div>
                  <div className="space-y-2 ">
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Email Support
                    </div>
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Save Groups in lists
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-[0px] dark:bg-primaryBg-dark md:mb-[140px]">
            <div className="flex flex-col-reverse gap-x-10 dark:text-white md:flex-row md:items-center md:px-40 ">
              {/* left */}
              <div className="py-[30px] px-6 md:pb-0">
                <div className="flex ">
                  <BarPill2
                    textOne={"SPEED"}
                    textTwo={"Increase Productivity"}
                    arrow={true}
                    w={"fit-content"}
                  />
                </div>
                <div className=" mt-5 flex text-4xl font-bold  dark:text-white md:text-[60px] md:leading-[66px]">
                  <h1 className=" text-[36px] md:text-5xl md:leading-[52.48px] md:tracking-[1px]">
                    Maximize Efficiency with our Bulk WhatsApp Sender!
                  </h1>
                </div>
                <p className="mt-9 mr-20 text-[20px]">
                  What makes us stand out? Our focus on automating and optimizing
                  your messaging tasks to save you loads of time.
                </p>
                <div className="flex flex-col mt-5 md:flex-row">
                  <div className="space-y-2 md:w-[50%]">
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Send Personalized Messages
                    </div>
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Set Time Gaps Between Messages
                    </div>
                    <div></div>
                  </div>
                  <div className="space-y-2 ">
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Upload Contacts via CSV/Excel
                    </div>
                    <div className=" flex items-center gap-x-4 text-[16px] md:text-[18px]">
                      <span>
                        <div className="flex items-center justify-center w-4 h-4">
                          <CheckList
                            fillColor={theme === "light" ? "black" : "white"}
                          />
                        </div>
                      </span>
                      Send Messages in Optimized Batches
                    </div>
                  </div>
                </div>
              </div>
              {/* right */}

              <div className="flex w-full flex-col items-center justify-end  gap-y-[30px] md:h-[full]  md:w-[75%] md:gap-y-[35px] md:self-end ">
                <div className="relative  flex items-center gap-x-6 rounded-[30px] px-5 py-5 shadow-boxShadow2 transition-all duration-200 ease-in-out md:right-10 md:px-10">
                  <SvgVectors
                    bgcolor={theme === "light" ? "#6540ff36" : "#6540ff36"}
                    svg={
                      <StoreIcon
                        fillColor={theme === "light" ? "#573ef7" : "#573ef7"}
                      />
                    }
                  />
                  <div className="flex flex-col">
                    <h1 className="text-base font-bold md:py-2 md:text-2xl">
                      Download extension
                    </h1>
                    <p className="text-sm ">Step 1</p>
                  </div>
                </div>
                <div className="relative flex items-center  gap-x-6 rounded-[30px] px-5 py-5 shadow-boxShadow2 transition-all duration-200 ease-in-out md:left-5 md:px-10">
                  <SvgVectors
                    bgcolor={theme === "light" ? "#00f0e036" : "#00f0e036"}
                    svg={
                      <MobileIcon
                        fillColor={theme === "light" ? "#08ccc6" : "#08ccc6"}
                      />
                    }
                  />
                  <div className="flex flex-col">
                    <h1 className="text-base font-bold md:py-2 md:text-2xl">
                      Go to WhatsApp Web
                    </h1>
                    <p className="text-sm ">Step 2</p>
                  </div>
                </div>

                <div className="relative  flex items-center gap-x-6 rounded-[30px]  px-5 py-5 shadow-boxShadow2 transition-all duration-200 ease-in-out md:left-5 md:px-10">
                  <SvgVectors
                    bgcolor={theme === "light" ? "#e90caa29" : "#e90caa29"}
                    svg={
                      <Graph
                        fillColor={theme === "light" ? "#df0bc6" : "#df0bc6"}
                      />
                    }
                  />
                  <div className="flex flex-col">
                    <h1 className="text-base font-bold md:py-2 md:text-2xl">
                      Start Sending Messages
                    </h1>
                    <p className="text-sm ">Step 3</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* cards */}
          <section className=" mb-[50px]  w-full p-3 dark:bg-primaryBg-dark md:mb-0" id="testimonial">
            <div className="  dark:bg-primaryBg-dark md:mx-auto md:h-[999px] md:w-[1315px]">
              <div className="dark:bg-bgp-dark relative  h-[55rem] w-full rounded-[20px] bg-bgp-light md:h-[999px]">
                <div className="absolute inset-0 flex flex-col gap-y-10">
                  <div className="flex h-[328px] w-[355px] flex-col items-center justify-center md:mt-[60px] md:h-[255px] md:w-full md:pt-[60px]">
                    <h1 className="mt-16 h-[118px] w-[315px]  text-center text-4xl font-bold dark:text-white md:h-[105px] md:w-[504px] md:text-5xl md:text-[48px] md:leading-[52.8px] md:tracking-[1px]">
                      Trusted by over 10K+ happy customers
                    </h1>
                    <p className=" mx-2 mt-[30px] px-5 text-center text-xl dark:text-white md:my-12 md:mx-auto md:mt-[30px] md:w-[45%] md:text-xl">
                      Don't just take our word for it, hear what members of our
                      friendly community have to say about us.
                    </p>
                  </div>
                  <div>
                    <Card direction="right" />
                  </div>
                  <div>
                    <Card direction="left" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* plans */}
          <section className="w-full dark:bg-primaryBg-dark">
            <div className="flex h-[] flex-col items-center justify-center gap-y-10  dark:text-white md:h-[90vh]">
              <h1 className="w-[90%] px-4 text-center text-[45px]  font-black leading-[49.5px] md:w-[50%] md:text-6xl">
                Simple pricing plans for every budget
              </h1>
              <p className=" px-4 text-center text-xl font-semibold text-[#1d243c] dark:text-[#ccd0df] md:my-0 md:w-[40%] md:px-0 md:text-xl">
                Straight forward and up front pricing plans designed to suit teams
                of any size.
              </p>
              <div className="my-4 flex gap-x-2 rounded-l-full rounded-r-full bg-[#ccd0df] px-1 py-1 dark:bg-[#24233c] dark:bg-opacity-50 dark:shadow-boxShadowVideo md:relative md:top-20 md:mt-0 md:py-2 md:px-2  ">
                <button
                  className={`flex items-center justify-center px-2 text-xl font-bold md:text-xl ${plan
                    ? `rounded-l-full rounded-r-full  bg-primaryBg-dark text-white dark:bg-white dark:text-primaryBg-dark md:px-3`
                    : `rounded-l-full rounded-r-full md:dark:bg-[#080c1c]`
                    }`}
                  onClick={(e) => setPlan(!plan)}
                >
                  Yearly
                </button>
                <button
                  className={`flex items-center justify-center px-2 text-xl  font-bold  md:px-3 md:py-1 md:text-xl ${!plan
                    ? `rounded-l-full rounded-r-full bg-primaryBg-dark text-white  dark:bg-white dark:text-primaryBg-dark md:px-3`
                    : `rounded-l-full rounded-r-full  md:dark:bg-[#080c1c]`
                    }`}
                  onClick={(e) => setPlan(!plan)}
                >
                  Monthly
                </button>
              </div>
            </div>
            {/* bg-starting */}
            <div className="dark:bg-bgp-dark relative h-[100rem] w-[screen] bg-bgp-light md:h-[90vh] md:w-[screen]">
              <div className="absolute inset-0 bg-bgc-blur-light dark:bg-bgc-blur-dark">
                <div
                  className="flex items-center justify-center w-full dark:text-white"
                  id="pricing"
                >
                  {plan ? (
                    <div className="flex  flex-col  items-center md:w-[100%] md:flex-row md:justify-evenly">
                      <Plans
                        planType={"Beginner"}
                        offer={"For everyone to use"}
                        cutOfferval={""}
                        price={"Free"}
                        choose={"Download Now"}
                        data={data.yearly.free}
                        theme={theme}
                        onClick={() => {
                          mixpanel.track(MixpanelEvents.DOWNLOAD_NOW_BUTTON, { source: 'yearly' });
                          window.open(
                            "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                          );
                        }}
                      />
                      <Plans
                        planType={"Advanced"}
                        offer={"Save 57%"}
                        cutOfferval={"₹6999"}
                        price={"₹2999"}
                        choose={"Choose Advanced🔥"}
                        data={data.yearly.paid}
                        theme={theme}
                        onClick={() => {
                          mixpanel.track(MixpanelEvents.CHOOSE_ADVANCED_PLAN_BUTTON, { source: 'yearly' });
                          window.open(
                            "https://buy.stripe.com/dR6cQHcqx1sZcLe14d"
                          );
                        }}
                      />
                      <Plans
                        planType={"Enterprise"}
                        offer={"Custom Solutions Tailored for Your Business"}
                        cutOfferval={""}
                        price={"Let's Talk"}
                        choose={"Contact Sales🔥"}
                        data={data.monthly.enterprise}
                        theme={theme}
                        onClick={() => {
                          mixpanel.track(MixpanelEvents.CONTACT_SALES_BUTTON, { source: 'yearly' });
                          window.open(
                            "https://notionforms.io/forms/wasurge-contact-sales"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col  items-center md:w-[100%] md:flex-row md:justify-evenly">
                      <Plans
                        planType={"Beginner"}
                        offer={"For everyone to use"}
                        cutOfferval={""}
                        price={"Free"}
                        choose={"Download Now"}
                        data={data.monthly.free}
                        theme={theme}
                        onClick={() => {
                          mixpanel.track(MixpanelEvents.DOWNLOAD_NOW_BUTTON, { source: 'monthly' });
                          window.open(
                            "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                          );
                        }}
                      />
                      <Plans
                        planType={"Advanced"}
                        offer={"Save 25%"}
                        cutOfferval={"₹1249"}
                        price={"₹999"}
                        choose={"Choose Advanced🔥"}
                        data={data.monthly.paid}
                        theme={theme}
                        onClick={() => {
                          mixpanel.track(MixpanelEvents.CHOOSE_ADVANCED_PLAN_BUTTON, { source: 'monthly' });
                          window.open(
                            "https://buy.stripe.com/28o7wnfCJ2x3h1udQY"
                          );
                        }}
                      />
                      <Plans
                        planType={"Enterprise"}
                        offer={"Custom Solutions Tailored for Your Business"}
                        cutOfferval={""}
                        price={"Let's Talk"}
                        choose={"Contact Sales🔥"}
                        data={data.monthly.enterprise}
                        theme={theme}
                        onClick={() => {
                          mixpanel.track(MixpanelEvents.CONTACT_SALES_BUTTON, { source: 'monthly' });
                          window.open(
                            "https://notionforms.io/forms/wasurge-contact-sales"
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="text-xs text-center">
                  *Tool functionality is subject to WhatsApp platform changes.
                  Please note that if any modifications occur, the tool may
                  experience disruptions or cease working entirely, and refunds
                  will not be provided.
                </div>
              </div>
            </div>
          </section>

          {/* plans */}
          {/* <section className="w-full dark:bg-primaryBg-dark">
          <div className="flex flex-col items-center justify-center gap-y-10 dark:text-white">
            <h1 className="w-[90%] px-4 text-center text-[45px]  font-black leading-[49.5px] md:w-[50%] md:text-6xl">
              Simple pricing plans for every budget
            </h1>
            <p className=" px-4 text-center text-xl font-semibold text-[#1d243c] dark:text-[#ccd0df] md:my-0 md:w-[40%] md:px-0 md:text-xl">
              Straight forward and up front pricing plans designed to suit teams
              of any size.
            </p>

          </div>
          <div className="dark:bg-bgp-dark relative h-[100rem] w-[screen] bg-bgp-light md:h-[90vh] md:w-[screen]">
            <div className="absolute inset-0 bg-bgc-blur-light dark:bg-bgc-blur-dark">
              <div
                className="flex items-center justify-center w-full dark:text-white"
                id="pricing"
              >
                <div className="flex flex-col  items-center md:w-[100%] md:flex-row md:justify-evenly">
                  <Plans
                    planType={"Beginner"}
                    offer={"For everyone to use"}
                    cutOfferval={""}
                    price={"Free"}
                    choose={"Download Now"}
                    data={data.monthly.free}
                    theme={theme}
                    onClick={() => {
                      window.open(
                        "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                      );
                    }}
                  />
                  <Plans
                    planType={"Advanced"}
                    offer={"Save 25%"}
                    cutOfferval={"₹999"}
                    price={"₹749/Mo"}
                    choose={"Choose Advanced🔥"}
                    data={data.monthly.paid}
                    theme={theme}
                    onClick={() => {
                      window.open(
                        "https://buy.stripe.com/dR66sj1LT3B726A4gl"
                      );
                    }}
                  />
                  <Plans
                    planType={"Enterprise"}
                    offer={"Custom Solutions Tailored for Your Business"}
                    cutOfferval={""}
                    price={"Let's Talk"}
                    choose={"Contact Sales🔥"}
                    data={data.monthly.enterprise}
                    theme={theme}
                    onClick={() => {
                      window.open(
                        "https://notionforms.io/forms/wasurge-contact-sales"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

          {/* Faq */}
          <section className=" dark:bg-primaryBg-dark" id="faq">
            <div className="flex flex-col items-center justify-center mx-5 dark:text-white ">
              <BarPill2 textOne={"FAQ"} textTwo={"Questions & answers"} />
              <h1 className="mb-[30px] text-center text-4xl font-bold md:text-left md:text-5xl ">
                Frequently asked questions
              </h1>
              <p className="flex h-[120px] w-[355px] justify-center text-center text-xl md:h-[60px] md:w-[600px] ">
                Don’t see an answer to your question below? Contact our friendly
                support team for assistance →
              </p>
            </div>
            <div className="mt-[40px] min-h-[70rem] px-3 dark:bg-primaryBg-dark md:flex md:h-[85vh] md:min-h-full md:justify-center md:dark:text-white">
              <div className=" flex flex-col   md:w-[45%] md:dark:text-white">
                <Faq
                  data={FaqData1}
                  handleChangeFaq={handleChangeFaq}
                  setExpanded={setExpanded}
                  expanded={expanded}
                />
              </div>
              <div className=" flex flex-col  md:w-[45%]  md:dark:text-white">
                <Faq2
                  data={FaqData2}
                  handleChangeFaq={handleChangeFaq}
                  setExpanded={setExpanded}
                  expanded={expanded}
                />
              </div>
            </div>
            <div className="mt-8  flex flex-col items-center justify-center gap-y-2 text-center text-base text-[#070b1b]  dark:text-white md:mt-64 md:mb-20 md:flex-row ">
              <span>Have another question ?</span>
              <button className="mx-2 w-fit border-b pb-[1px]">
                <a href="mailto:support@wasurge.com">Send us an email</a>
              </button>
              →
            </div>
          </section>

          {/* ready */}
          <section className="dark:bg-primaryBg-dark">
            <div className="text-center text-5xl font-bold  dark:text-white md:text-[48px] md:leading-[89.1px]">
              <div className="p-3 ">
                <div className="dark:bg-bgp-dark  relative mb-10 inline-flex h-[27rem] w-full rounded bg-bgp-light shadow-boxShadow2 dark:shadow-boxShadowVideo md:h-[417.6px] md:w-[1152px]">
                  <div className="absolute inset-0 flex flex-col gap-y-10">
                    <h1 className="mx-5 mt-10 flex items-center justify-center text-4xl text-[49px] leading-[49.5px]  md:mx-64 md:mt-20 md:text-6xl md:leading-[66px] md:tracking-[1px]">
                      Ready to take your WhatsApp on next level?
                    </h1>
                    <p className="text-center text-xl dark:text-white md:my-[-20px] md:mx-auto md:w-[45%] md:text-xl">
                      There has never been a better time than right now.
                    </p>
                    <div className="flex justify-center gap-5 mb-5 text-base">
                      <button
                        className="flex items-center justify-center rounded border-4 border-solid  border-black bg-black px-5 py-1.5 font-bold text-white hover:opacity-70 dark:border-white dark:bg-white dark:font-bold dark:text-black dark:hover:opacity-70"
                        onClick={() => {
                          mixpanel.track(MixpanelEvents.ADD_TO_CHROME_BUTTON, { source: 'footer' });
                          window.open(
                            "https://chrome.google.com/webstore/detail/wasurge/bdnjmmbbchjkbnmbphchknkalfakofnj"
                          );
                        }}
                      >
                        <ChromeLogo
                          fillColor={theme === "light" ? "white" : "black"}
                        />
                        <span className="ml-1 mr-1"> Add to Chrome</span>
                        <span className="text-xs"> (it's free)</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pb-10 dark:bg-primaryBg-dark md:pb-[80px]">
            <div className=" flex h-[110px] items-center justify-center  bg-primaryBg-dark text-white   dark:bg-primaryBg-dark md:mx-auto md:h-[100px] ">
              <Marquee gradient={false} speed={50} pauseOnHover>
                <div className="flex ">
                  {obj.map((item, index) => {
                    return (
                      <div className="flex gap-x-4" key={index}>
                        {item.logo}
                        {"⚡️"}
                        <span className="ml-2 text-xl font-semibold dark:text-white">
                          {item.name}
                          {"Build Your Brand"}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Marquee>
            </div>
          </div>  */}
          </section>

          {/* footer */}
          <section className="dark:bg-primaryBg-dark">
            <div className="flex flex-col justify-center w-full px-10 dark:text-white md:flex-row md:justify-between md:px-20">
              <div className="text-center md:text-left">
                <h1 className="text-2xl font-bold">WAsurge</h1>
                <p className="md:w-[357px]">
                  #1 WhatsApp Automation Tool For Businesses
                </p>
              </div>
              <div className="flex flex-col">
                <div className="space-y-5">
                  {/* <h1 className="font-bold">Pages</h1> */}
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      window.open("/about");
                    }}
                  >
                    About
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      window.open("/terms-of-service");
                    }}
                  >
                    Terms and Conditions
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      window.open("/refund");
                    }}
                  >
                    Refund
                  </p>
                  <p className="cursor-pointer">
                    <a href="#pricing">Pricing</a>
                  </p>
                  <p className="cursor-pointer">
                    <a href="#faq">FAQ</a>
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      window.open("/privacy-policy");
                    }}
                  >
                    Privacy Policy
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      window.open("/contact");
                    }}
                  >
                    Contact
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between py-2 pl-2 md:h-[64px] md:w-full  md:pl-[100px]">
              <div className="text-black dark:text-white">
                © Copyright 2022・WAsurge・ All rights reserved.
              </div>
              <div className="flex gap-3 px-4 md:pr-20">
                <span
                  className="transition-all ease-in-out hover:-translate-y-2 hover:scale-125"
                  onClick={() => {
                    window.open("https://twitter.com/rhutikG");
                  }}
                >
                  <Tweeter fillColor={theme === "light" ? "black" : "white"} />
                </span>
                {/* <span className="transition-all ease-in-out hover:-translate-y-2 hover:scale-125">
                <Facebook fillColor={theme === "light" ? "black" : "white"} />
              </span> */}
                <span
                  className="transition-all ease-in-out hover:-translate-y-2 hover:scale-125"
                  onClick={() => {
                    window.open("https://www.youtube.com/@rhutikgiradkar");
                  }}
                >
                  <Youtube fillColor={theme === "light" ? "black" : "white"} />
                </span>
                <span
                  className="transition-all ease-in-out hover:-translate-y-2 hover:scale-125"
                  onClick={() => {
                    window.open("https://instagram.com/acchacoder");
                  }}
                >
                  <InstagramIcon
                    fillColor={theme === "light" ? "black" : "white"}
                  />
                </span>
              </div>
            </div>
          </section>
        </div>}
      </div>
    </>
  );
}

export default Homepage;
