import React from "react";

function PrivacyPolicy() {
  return (
    <div className=" p-8">
      <h1 className="text-4xl font-bold">WAsurge Privacy Policy</h1>
      <br />

      <h3 className="text-xl">SECTION 1 - INFORMATION COLLECTION</h3>
      <br />

      <p>
        When you use our WAsurge Chrome extension, we may collect personal
        information you provide us, such as your name, email address, and any
        other necessary details for account creation or support purposes.
      </p>
      <br />

      <p>
        Additionally, when you use WAsurge, we automatically receive your
        computer's Internet Protocol (IP) address to help us learn about your
        browser and operating system for better user experience.
      </p>
      <br />

      <p>
        Email marketing (if applicable): With your permission, we may send you
        emails about WAsurge, new features, updates, and other relevant
        information.
      </p>
      <br />

      <h3 className="text-xl">SECTION 2 - CONSENT</h3>
      <br />

      <p className="text-lg">How do we obtain your consent?</p>
      <br />
      <p>
        When you provide us with personal information for account creation,
        customer support, or any other specific purpose, we assume that you
        consent to our collection and usage of the information for that
        particular reason. If we need your personal information for any
        secondary reason, such as marketing, we will ask for your explicit
        consent or give you the option to decline.
      </p>
      <br />
      <p className="text-lg">How can you withdraw your consent?</p>
      <br />
      <p>
        If you wish to withdraw your consent for us to contact you or collect,
        use, or disclose your information, you can do so at any time by
        contacting us at support@wasurge.com.
      </p>
      <br />

      <h3 className="text-xl">SECTION 3 - DISCLOSURE</h3>
      <br />

      <p>
        We may disclose your personal information if required by law or if you
        violate our Terms of Service.
      </p>
      <br />

      <h3 className="text-xl">SECTION 4 - PAYMENT</h3>
      <br />

      <p>
        Please note that WAsurge itself does not process payments. Any
        payment-related information and transactions are managed by third-party
        payment gateways, and we recommend you review their respective privacy
        policies and terms of service.
      </p>
      <br />

      <h3 className="text-xl">SECTION 5 - THIRD-PARTY SERVICES</h3>
      <br />

      <p>
        Third-party providers we use will only collect, use, and disclose your
        information as necessary to perform the services they provide to us. We
        recommend that you read their privacy policies to understand how they
        handle your personal information.
      </p>
      <br />

      <p>
        Remember that certain providers may be located in or have facilities in
        different jurisdictions, and your information may be subject to the laws
        of those jurisdictions when engaging in transactions involving
        third-party services.
      </p>
      <br />

      <p>
        We are not responsible for the privacy practices of other websites or
        applications you may be redirected to from WAsurge, and we encourage you
        to read their privacy statements.
      </p>
      <br />

      <h3 className="text-xl">SECTION 6 - SECURITY</h3>
      <br />

      <p>
        We take reasonable precautions and follow industry best practices to
        protect your personal information and ensure it is not lost, misused,
        accessed, disclosed, altered, or destroyed inappropriately.
      </p>
      <br />

      <h3 className="text-xl">SECTION 7 - COOKIES</h3>
      <br />

      <p>
        We use cookies to maintain your user session. Cookies are not used to
        personally identify you on other websites.
      </p>
      <br />

      <h3 className="text-xl">SECTION 8 - AGE OF CONSENT</h3>
      <br />

      <p>
        By using WAsurge, you represent that you are at least the age of
        majority in your state or province of residence or that you are the age
        of majority and have given us consent to allow any minor dependents to
        use WAsurge.
      </p>
      <br />

      <h3 className="text-xl">SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h3>
      <br />

      <p>
        We reserve the right to modify this privacy policy at any time. Please
        review it frequently for updates. Changes will take effect immediately
        upon posting on our website. If we make material changes, we will notify
        you here so that you are aware of the information we collect, how we use
        it, and under what circumstances we use and/or disclose it.
      </p>
      <br />

      <p>
        In the event that WAsurge is acquired or merged with another company,
        your information may be transferred to the new owners so that we may
        continue providing our services to you.
      </p>
      <br />

      <h3 className="text-xl">QUESTIONS AND CONTACT INFORMATION</h3>
      <br />

      <p>
        If you would like to access, correct, amend, or delete any personal
        information we have about you, register a complaint, or need more
        information, please contact our Privacy Compliance Officer at
        support@wasurge.com.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
