import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

function Faq2({ data, handleChangeFaq, expanded }) {
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    background: "transparent",
    // borderTop: `2px solid #3E3E3E`,
    // borderBottom: `2px solid #3E3E3E`,
    // "&:is(:first-child)": {
    //   borderTop: "none",
    // },
    // "&:not(:last-child)": {
    //   borderBottom: 0,
    // },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    background: "transparent",
    // backgroundColor:
    //   theme.palette.mode === "dark"
    //     ? "rgba(255, 255, 255, .05)"
    //     : "rgba(0, 0, 0, .03)",
    // flexDirection: "row-reverse",
    // ":hover":{
    //     boxShadow:" 0px 6px 0px #988aff55",
    // },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(2),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    padding: theme.spacing(2),
    paddingTop: "0px",
    background: "transparent",
    borderTop: "none",
  }));

  return (
    <>
      {/* <div className="h-screen flex flex-col items-center justify-center bg-primaryBg-dark"> */}
      {data.map((val, index) => {
        return (
          <div
            id={`${index}`}
            key={`${index}`}
            className="mx-auto my-3  rounded-[30px] shadow-boxShadow2 md:w-[95%] "
          >
            <Accordion
              className=" bg-transparent "
              expanded={expanded === `panel2${index}`}
              onChange={handleChangeFaq(`panel2${index}`)}
            >
              <AccordionSummary
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography className="mb-[20px] bg-transparent p-[15px] font-bold leading-[27px] tracking-[0.015em] text-pColor dark:text-white  md:p-[30px] ">
                  <span className="text-lg  md:text-xl">
                    <strong>{val.summary}</strong>
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className=" text-textGrey bg-transparent px-8 text-lg font-normal tracking-[0.015rem] dark:text-white ">
                  {val.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      {/* </div> */}
    </>
  );
}

export default Faq2;
