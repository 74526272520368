import React from "react";
import Marquee from "react-fast-marquee";

const arr = [
  {
    stars: "★★★★",
    des: "Fantastic, I’m totally blown away. Such an amazing product, I highly recommend trying it.",
    name: "Harshal",
  },
  {
    stars: "★★★★",
    des: "This is the best WhatsApp Plugin I have found. This is fast and simple.",
    name: "Prasad",
  },
  {
    stars: "★★★★",
    des: "Holy Shit. This actually works and saved thousands of hours for me and my team",
    name: "Rhutik",
  },
  {
    stars: "★★★★",
    des: "Best in the business. WASurge is simple and cost-effective compared to other plugins out there",
    name: "Saurabh",
  },
  {
    stars: "★★★★",
    des: "Bulk Group Automations are highlight for me. What an insanely amazing tool!",
    name: "Sheetal",
  },
  {
    stars: "★★★★",
    des: "It saved 2 hours of work for me everyday. Works flawlessly.",
    name: "Nupur",
  },
];

function Card({ direction = "right" }) {
  return (
    <Marquee gradient={false} speed={50} direction={direction} pauseOnHover>
      <div className="flex ">
        {arr.map((item, index) => {
          return (
            <div
              className=" mx-5 w-[300px] rounded-2xl bg-transparent text-left   text-[#1d243c] "
              key={index}
            >
              <div className="rounded-2xl  bg-[#ffffff] bg-opacity-[0.25]  p-[30px] before:rounded-2xl dark:bg-[#070b1b] dark:bg-opacity-[0.5]">
                <div className="z-10 " key={index}>
                  <div className="text-sm tracking-[10px] text-primaryBg-dark dark:text-white">
                    {item.stars}
                  </div>
                  <div className="my-5 text-lg text-primaryBg-dark dark:text-[#ffffff] ">
                    {item.des}
                  </div>
                  <div className="text-sm font-extrabold text-primaryBg-dark dark:text-[#ffffff]">
                    {item.name}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Marquee>
  );
}

export default Card;
