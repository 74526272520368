// mixpanelContext.js
import mixpanel from 'mixpanel-browser';
import { createContext, useContext, useEffect, useState } from 'react';

export const MixpanelContext = createContext();

export const MixpanelProvider = ({ children }) => {
	const [isMixpanelInitialized, setisMixpanelInitialized] = useState(false);

	useEffect(() => {
		mixpanel.init("9076102ad1d88f6dd5505abf00dbee1b", {
			debug: true,
			ignore_dnt: true,
		});
		setisMixpanelInitialized(true);
	}, []);

	return <MixpanelContext.Provider value={isMixpanelInitialized}>
		{children}
	</MixpanelContext.Provider>;
};

export const useMixpanel = () => {
	const isMixpanelInitialized = useContext(MixpanelContext);
	if (!isMixpanelInitialized) {
		return { mixpanel: null, isMixpanelInitialized: false };
	}
	return { mixpanel, isMixpanelInitialized: true };
};
