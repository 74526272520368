import React from "react";

function BgContainer({ children }) {
  return (
    <>
      <div className="dark:bg-primaryBg-dark">
        <div className="dark:bg-bgp-dark relative h-[40rem] w-full bg-bgp-light md:h-[100vh]">
          <div className="absolute inset-0  bg-bgc-blur-light dark:bg-bgc-blur-dark ">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default BgContainer;
