import React from 'react'

function StarFilledIcon({fillColor}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={fillColor}>
            <path d="M11.776 4.454a.25.25 0 0 1 .448 0l2.069 4.192a.25.25 0 0 0 .188.137l4.626.672a.25.25 0 0 1 .139.426l-3.348 3.263a.25.25 0 0 0-.072.222l.79 4.607a.25.25 0 0 1-.362.263l-4.138-2.175a.25.25 0 0 0-.232 0l-4.138 2.175a.25.25 0 0 1-.363-.263l.79-4.607a.25.25 0 0 0-.071-.222L4.754 9.881a.25.25 0 0 1 .139-.426l4.626-.672a.25.25 0 0 0 .188-.137l2.069-4.192z" />
        </svg>
    )
}

export default StarFilledIcon