import React from 'react'

function SvgVectors({ svg, bgcolor }) {
    return (
        <div className={`flex justify-center items-center rounded-full  h-[50px] w-[50px] p-2 dark:bg-opacity-25 `}  
        style={{backgroundColor: bgcolor}} >
            {svg}
        </div>
    )
}

export default SvgVectors