import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import ExtensionPage from "./pages/Extension";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/about";
import Contact from "./pages/contact";
import Refund from "./pages/refund";
import TnC from "./pages/tnc";
import { MixpanelProvider } from "./services/mixpanel/mixpanelContext";
import "./styles.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/extension",
    element: <ExtensionPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-of-service",
    element: <TnC />,
  },
  {
    path: "/refund",
    element: <Refund />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MixpanelProvider>
      <RouterProvider router={router} />
    </MixpanelProvider>
  </React.StrictMode >
);