import React from "react";

function Contact() {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-gray-100 p-8">
      <div className="max-w-xl bg-white shadow-lg rounded-lg p-6">
        <div className="text-4xl font-bold text-center text-gray-800">Contact Us</div>
        <p className="mt-4 text-lg text-gray-600">
          Email support available! Write to us at:
          <a href="mailto:support@wasurge.com" className="ml-1 text-blue-600 hover:text-blue-700 hover:underline">
            support@wasurge.com
          </a> to get a response within 24 hours.
        </p>
        <div className="mt-8">
          <div className="flex items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 10C20 16 12 22 12 22C12 22 4 16 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p className="ml-2 text-lg text-gray-700">
              Near Jama Masjid, Rajura, Maharashtra, 442905
            </p>
          </div>
          <div className="flex items-center mt-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.0001 16.92V19.92C22.0012 20.1985 21.9441 20.4741 21.8326 20.7293C21.721 20.9845 21.5574 21.2136 21.3521 21.4018C21.1469 21.5901 20.9046 21.7335 20.6408 21.8227C20.377 21.9119 20.0974 21.945 19.8201 21.92C16.7429 21.5856 13.7871 20.5341 11.1901 18.85C8.77388 17.3146 6.72539 15.2661 5.19006 12.85C3.50003 10.2412 2.4483 7.27097 2.12006 4.17997C2.09507 3.90344 2.12793 3.62474 2.21656 3.3616C2.30518 3.09846 2.44763 2.85666 2.63482 2.6516C2.82202 2.44653 3.04986 2.28268 3.30385 2.1705C3.55783 2.05831 3.8324 2.00024 4.11006 1.99997H7.11006C7.59536 1.9952 8.06585 2.16705 8.43382 2.48351C8.80179 2.79996 9.04213 3.23942 9.11005 3.71997C9.23668 4.68004 9.47151 5.6227 9.81006 6.52997C9.9446 6.8879 9.97372 7.27689 9.89396 7.65086C9.81421 8.02482 9.62892 8.36809 9.36005 8.63998L8.09006 9.90997C9.51361 12.4135 11.5865 14.4864 14.0901 15.91L15.3601 14.64C15.6319 14.3711 15.9752 14.1858 16.3492 14.1061C16.7231 14.0263 17.1121 14.0554 17.4701 14.19C18.3773 14.5285 19.32 14.7634 20.2801 14.89C20.7658 14.9585 21.2095 15.2032 21.5266 15.5775C21.8437 15.9518 22.0122 16.4296 22.0001 16.92Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.05 2C16.0883 2.21477 17.9922 3.1188 19.4469 4.56258C20.9016 6.00636 21.8199 7.90341 22.05 9.94" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.05 6C15.0336 6.19394 15.9361 6.67903 16.6404 7.39231C17.3448 8.10559 17.8185 9.01413 18 10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <a href="tel:+918767869873" className="ml-2 text-lg text-blue-600 hover:text-blue-700 hover:underline">
              8767869873
            </a>
          </div>
          <div className="flex items-center mt-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M22 7L13.03 12.7C12.7213 12.8934 12.3643 12.996 12 12.996C11.6357 12.996 11.2787 12.8934 10.97 12.7L2 7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <a href="mailto:support@wasurge.com" className="ml-2 text-lg text-blue-600 hover:text-blue-700 hover:underline">
              support@wasurge.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
