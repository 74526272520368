import * as React from "react";
const Arrow = ({ fillColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={159} height={71} fill="none">
    <path
      stroke={fillColor || "#0B141F"}
      strokeLinecap="round"
      strokeWidth={2}
      d="M157.676 50.992s-26.802 23.953-51.849 16.95C88.155 63 77.488 44.386 86.523 36.158c4.778-4.352 10.512-.262 11.699 4.485 2.145 8.58-14.795 18.914-26.714 18.914-41.338 0-64.737-54.792-64.737-54.792"
    />
    <path
      stroke={fillColor || "#0B141F"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.615 13.725 6.18 4.276 16.576 6.54"
    />
  </svg>
);
export default Arrow;
