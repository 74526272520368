import React from "react";

function Checkmark({currentColor}) {
  return (
    <svg
      fill={`${currentColor}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={25}
      width={25}
    >
      <path d="m16.7 7.1-6.3 8.5-3.3-2.5-.9 1.2 4.5 3.4L17.9 8z" />
    </svg>
  );
}

export default Checkmark;
