import React from "react";

function Logo() {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="5" y="5" width="56" height="56" rx="28" fill="#D1FADF" />
        <path
          d="M34.8889 14L16 36.8H33L31.1111 52L50 29.2H33L34.8889 14Z"
          stroke="#039855"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="5"
          y="5"
          width="56"
          height="56"
          rx="28"
          stroke="#ECFDF3"
          strokeWidth="10"
        />
      </svg>
    </>
  );
}

export default Logo;
