export const MixpanelEvents = {
	NAV_DOWNLOAD_BUTTON: 'Nav Download Button',
    NAV_TESTIMONIALS_BUTTON: 'Nav Testimonials Button',
    NAV_PRICING_BUTTON: 'Nav Pricing Button',
    ADD_TO_CHROME_BUTTON: 'Add to Chrome Button',
    TOGGLE_DARK_MODE: 'Toggle Dark Mode',
    DOWNLOAD_NOW_BUTTON: 'Download Now Button',
    CHOOSE_ADVANCED_PLAN_BUTTON: 'Choose Advanced Plan Button',
    CONTACT_SALES_BUTTON: 'Contact Sales Button',
};
