import React from "react";

function ChromeLogo({ fillColor }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_501_51181)">
        <path
          d="M0 12C0 9.81563 0.584531 7.7625 1.60641 5.95781L6.75469 14.9203C7.78125 16.7578 9.74531 18 12 18C12.6703 18 13.2703 17.8922 13.9125 17.6906L10.3359 23.8875C4.49531 23.0766 0 18.0609 0 12ZM17.1141 15.075C17.6906 14.175 18 13.0828 18 12C18 10.2094 17.2125 8.60156 15.9703 7.5H23.1281C23.6906 8.8875 24 10.4109 24 12C24 18.6281 18.6281 23.9578 12 24L17.1141 15.075ZM22.3969 6H12C9.05156 6 6.67031 8.06719 6.11719 10.8141L2.54016 4.61578C4.73438 1.80609 8.15625 0 12 0C16.4438 0 20.3203 2.41313 22.3969 6ZM7.875 12C7.875 9.72188 9.72188 7.875 12 7.875C14.2781 7.875 16.125 9.72188 16.125 12C16.125 14.2781 14.2781 16.125 12 16.125C9.72188 16.125 7.875 14.2781 7.875 12Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_501_51181">
          <rect width="24" height="24" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChromeLogo;
