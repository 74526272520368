import React from "react";

function Refund() {
  return (
    <div className=" p-8">
      <div className="text-xl font-semibold">Returns/Cancellations/Refund </div>
      <br />
      <div>
        We only offer digital products so no refund cancellation or returns are
        possible after an order has been placed. If you have a continuing
        subscription then you can cancel anytime and no charges will be deducted
        after you cancel the subscription/membership. However any charges
        deducted before cancellation of membership cannot be refunded!
      </div>
      <br />
      <div>
        For any other questions regarding cancellation or refunds send us an
        email at support@wasurge.com
      </div>
    </div>
  );
}

export default Refund;
