import React from "react";

function BarPill({ textOne, textTwo, arrow }) {
  return (
    <div className="my-5 rounded-l-full rounded-r-full bg-slate-50 bg-opacity-40 py-1 px-[6px] flex items-center dark:bg-primaryBg-dark dark:bg-opacity-70  dark:text-white">
      <span className="mr-2 rounded-l-full rounded-r-full bg-[white] py-[3px] px-2 text-sm font-extrabold tracking-[0.09rem] text-[black] opacity-100 dark:bg-[#070b1b] dark:text-white">
        {textOne}
      </span>
      <span className="mr-2  bg-[] font-bold tracking-[0.07rem] opacity-100">
        {textTwo}
      </span>
      <span className="text-xl pr-[5px]">{arrow ? "→" : null}</span>
    </div>
  );
}

export default BarPill;
