import React from 'react'

function Graph({ fillColor }) {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={30} fill={fillColor} ><path d="M11.25 5h1.5v15h-1.5V5zM6 10h1.5v10H6V10zm12 4h-1.5v6H18v-6z" /></svg>

    )
}

export default Graph