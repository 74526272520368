import React from "react";
import Checkmark from "../svgs/Checkmark";

function Plans({
  theme,
  planType,
  offer,
  cutOfferval,
  price,
  data,
  choose,
  svg,
  onClick,
}) {
  return (
    <div className="my-7 flex flex-col items-center rounded-[30px] bg-white  bg-opacity-60 px-16 py-6 pt-3 pb-7 shadow-boxShadow2 dark:bg-transparent dark:shadow-boxShadowVideo md:w-[335px] md:px-0 md:py-8">
      <div className="text-center">
        <h1 className="my-3 text-xl font-bold md:text-2xl">{planType}</h1>
        <p className="my-3 md:text-sm">
          {offer} <span className="line-through ">{cutOfferval}</span>
        </p>
        <div className="my-3 text-4xl font-bold md:text-5xl">{price}</div>
      </div>
      <div className="mx-auto flex w-fit flex-col items-center justify-center gap-y-2 text-base text-[#1d243c] md:items-start">
        {data.map((item, index) => {
          return (
            <div className="flex  dark:text-slate-200 md:text-lg" key={index}>
              <span>
                <Checkmark
                  currentColor={theme !== "light" ? "white" : "black"}
                />
              </span>
              {item}
            </div>
          );
        })}
      </div>
      <div
        className="mt-3 flex  h-11 w-[200px] items-center justify-center gap-x-3 rounded-l-full rounded-r-full bg-black py-1 px-2 text-center text-base  font-semibold  tracking-wider text-white shadow-boxShadowVideo transition-all duration-200 ease-in-out hover:opacity-70 dark:bg-white dark:text-black dark:hover:opacity-70 md:mx-auto"
        onClick={onClick}
      >
        {`${svg ? <span>{svg}</span> : ""}`}
        {choose}
      </div>
    </div>
  );
}

export default Plans;
