import React from "react";

function BarPill2({ textOne, textTwo, arrow, w }) {
  return (
    <div
      className="dark:bg-[#23223b]dark:bg-opacity-70 my-5 rounded-l-full rounded-r-full bg-slate-200 bg-opacity-40  flex items-center py-1 px-[6px] dark:text-white"
      style={{ width: w }}
    >
      <span className="mr-2 rounded-l-full rounded-r-full bg-[white] py-[3px] px-2 text-sm font-extrabold tracking-[0.09rem] text-[black] opacity-100 dark:bg-[#070b1b] dark:text-white">
        {textOne}
      </span>
      <span className="mr-2  font-bold tracking-[0.07rem] opacity-100">
        {textTwo}
      </span>
      <span className="text-xl pr-[4px]">{arrow ? "→" : null}</span>
    </div>
  );
}

export default BarPill2;
